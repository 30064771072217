import styled from '@emotion/styled';
import { maxBreakpoints } from '@/utils/mediaQuery';

const CustomDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  border-top: 1px dashed var(--text-secondary-color);
  border-bottom: none;
  :last-child {
    margin-bottom: 0;
  }
`;
const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

const InfoLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1rem;
  width: 100%;
  align-items: center;
`;

const Divider = styled.hr`
  border: 4px solid #f8f8f8;
  width: 100%;
  margin: 20px 0;
`;
const StyledNameFonts = styled.div`
  font-size: 20px;
  font-weight: 500;
  > span {
    font-size: 14px;
    color: var(--text-secondary-color);
  }
`;
const StyledDeptFonts = styled.div`
  font-size: 14px;
  color: var(--text-secondary-color);
`;

const StyledImg = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #e7e9ec;
`;

const ImgLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Layout = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  gap: 1rem;
  min-width: 22rem;
  //min-height: 18rem;
`;

const StyledCloseButton = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
`;

const StyledTeams = styled.a`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.42rem;
  border: 1px #e4e6ef solid;
  background-color: var(--button-background-color);
  color: var(--text-primary-color);
  height: 3rem;
  width: 100%;
  gap: 0.5rem;
`;

StyledTeams.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
  type: 'button',
};

const StyledName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  font-family: var(--font-Pretendard);
`;

const TopLayout = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (${maxBreakpoints.md}) {
    flex-direction: column;
  }
`;

const BottomLayout = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0 20px 20px 20px;
`;

const StyledEmpty = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const StyledContent = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 1rem;
  font-weight: 350;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
`;
const StyledDate = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-weight: 350;
`;

const LeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 252px;
`;

const StyledIconButton = styled.button`
  --fa-secondary-opacity: 1;
  --fa-primary-color: ${({ theme }) => theme.colors.textSecondary};
  --fa-secondary-color: ${({ theme }) => theme.colors.textSecondary};
  &:hover {
    --fa-primary-color: ${({ theme }) => theme.colors.primary};
    --fa-secondary-color: ${({ theme }) => theme.colors.primary};
  }
  width: 18px;
  height: 18px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
`;

const StyleCopyLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCopyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: underline;
`;

const IconButtonContainer = styled.div<{ disabled: boolean }>`
  box-sizing: content-box;
  border: 1px solid #e7e9ec;
  border-radius: 6px;
  padding: 10px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 66px;
  height: 48px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${({ disabled }) => (disabled ? '#F9FAFB' : 'white')};
`;

export {
  StyledDate,
  CustomDivider,
  ContentsContainer,
  InfoLayout,
  StyledNameFonts,
  StyledDeptFonts,
  StyledImg,
  Layout,
  ImgLayout,
  StyledCloseButton,
  StyledTeams,
  StyledName,
  Container,
  TopLayout,
  LeftLayout,
  BottomLayout,
  StyledEmpty,
  StyledContent,
  StyledTitle,
  StyledIconButton,
  HeaderContainer,
  TitleLayout,
  StyleCopyLayout,
  StyledCopyButton,
  IconButtonContainer,
  Divider,
};
