type P = {
  size?: number;
};

const NoProfileImg = ({ size }: P) => {
  return (
    <>
      <svg
        width={size ? size.toString() : '32'}
        height={size ? size.toString() : '32'}
        viewBox={
          size ? `0 0 ${size.toString()} ${size.toString()}` : '0 0 32 32'
        }
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2480_15881)">
          <rect width="32" height="32" fill="#D4D7DB" />
          <path
            d="M6.5498 30.0316V30.0316C6.5498 25.2054 10.4622 21.293 15.2885 21.293C15.741 21.293 16.1934 21.293 16.6459 21.293C21.4722 21.293 25.3846 25.2054 25.3846 30.0316V30.0316"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="15.7919"
            cy="13.7421"
            r="4.24207"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </g>
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="15.5"
          stroke="#B7BBC2"
        />
        <defs>
          <clipPath id="clip0_2480_15881">
            <rect width="32" height="32" rx="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default NoProfileImg;
