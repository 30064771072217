import { ReactNode } from 'react';
import styled from '@emotion/styled';

type P = {
  left: string;
  right: ReactNode;
};

const EmployeeContents = ({ left, right }: P) => {
  return (
    <ContentLayout>
      <StyledTitle>{left}</StyledTitle>
      <StyledContent>{right}</StyledContent>
    </ContentLayout>
  );
};

const StyledContent = styled.div`
  font-size: 1rem;
  font-weight: 350;
  > a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 400;
`;

const ContentLayout = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  min-width: 252px;
`;
export default EmployeeContents;
