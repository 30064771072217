import SvgImage from '@/components/Module/SvgImage';
import styled from '@emotion/styled';

type P = {
  keyword: string;
  onClick: (keyword: string) => void;
  onDelete: (keyword: string) => void;
};

const RoundedButton = ({ keyword, onClick, onDelete }: P) => {
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete(keyword);
  };

  return (
    <button
      onClick={() => onClick(keyword)}
      style={{
        border: '1px solid #E7E9EC',
        borderRadius: '24px',
        display: 'flex',
        gap: '6px',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        padding: '8px 15px 8px 20px',
        color: '#5F636B',
        whiteSpace: 'nowrap',
        fontSize: '14px',
      }}
    >
      {keyword}
      <StyledButton onClick={(e) => handleDelete(e)}>
        <SvgImage name={'x'} />
      </StyledButton>
    </button>
  );
};

export default RoundedButton;

const StyledButton = styled.button`
  padding: 0;
  margin-left: 10px;
  > svg {
    vertical-align: middle;
  }
`;
