const LeaderIcon = () => {
  return (
    <div
      style={{
        backgroundColor: 'black',
        color: 'white',
        padding: '7px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '4px',
        fontSize: '9px',
        fontWeight: '700',
        marginLeft: 'auto',
      }}
    >
      Leader
    </div>
  );
};

export default LeaderIcon;
