import styled from '@emotion/styled';
import { Rectangle } from '@mui/icons-material';

const Bar = styled(Rectangle)`
  width: 9%;
  height: 4px;
  background-color: #e7e9ec;
  color: #e7e9ec;
  border-radius: 0.42rem;
`;
const Container = styled.div`
  font-family: var(--font-Pretendard);
  padding: 0 20px;
`;

export { Bar, Container };
