import styled from '@emotion/styled';

const Layout = styled.button`
  display: flex;
  align-self: flex-start;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: start;
  padding: 0;
  font-family: var(--font-Pretendard);
`;

const StyledImg = styled.img`
  border-radius: 50%;
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border: 1px solid #e7e9ec;
`;

export { Layout, StyledImg };
