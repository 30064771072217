import styled from '@emotion/styled';

const CenterLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 400px);
`;

export { CenterLayout };
