import { IconButtonContainer } from '@/components/MobileOrganization/Content/ProfileCardDialog/styles';
import React from 'react';
import SvgImage from '@/components/Module/SvgImage';
import { useTranslation } from 'react-i18next';

type P = {
  phoneMobile?: string;
  phoneInside?: string;
  email?: string;
};

const DirectAsset = ({ phoneInside, phoneMobile, email }: P) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',

        justifyContent: 'center',
        gap: '14px',
        marginTop: '20px',
      }}
    >
      <a
        href={`tel:${phoneMobile}`}
        style={{
          pointerEvents: phoneMobile ? 'auto' : 'none',
        }}
      >
        <IconButtonContainer disabled={!phoneMobile}>
          <SvgImage name={'phone'} />
          <div>{t('휴대폰')}</div>
        </IconButtonContainer>
      </a>
      <a
        href={`sms:${phoneMobile}`}
        style={{
          pointerEvents: phoneMobile ? 'auto' : 'none',
        }}
      >
        <IconButtonContainer disabled={!phoneMobile}>
          <SvgImage name={'message'} />
          <div>{t('문자')}</div>
        </IconButtonContainer>
      </a>
      <a
        href={`tel:${phoneInside}`}
        style={{
          pointerEvents: phoneInside ? 'auto' : 'none',
        }}
      >
        <IconButtonContainer disabled={!phoneInside}>
          <SvgImage name={'phone'} />
          <div>{t('내선전화')}</div>
        </IconButtonContainer>
      </a>
      <a
        href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
        target={'_blank'}
        rel={'noopener noreferrer'}
        type={'button'}
        style={{
          pointerEvents: email ? 'auto' : 'none',
        }}
      >
        <IconButtonContainer disabled={!email}>
          <SvgImage name={'teams'} />
          <div>{t('팀즈')}</div>
        </IconButtonContainer>
      </a>
    </div>
  );
};

export default DirectAsset;
