import {
  useDeletePassportSearchHistory,
  useGetPassportSearchHistory,
  useSearchHistory,
} from '@/hooks/apiHooks';
import RoundedButton from '@/components/MobileOrganization/Search/RecentSearch/RoundedButton';

import SubProfile from '@/components/MobileOrganization/Content/Profile/SubProfile';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const RecentSearch = () => {
  const { data, mutate } = useGetPassportSearchHistory();

  const { data: resentViewData } = useSearchHistory();
  const { setValue } = useFormContext<{ keyword: string }>();

  const { t } = useTranslation();

  const onClick = (keyword: string) => {
    setValue('keyword', keyword);
  };

  const onDelete = (keyword: string) => {
    useDeletePassportSearchHistory(keyword).then(() => mutate());
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {data && data?.length > 0 && (
        <div>
          <div style={{ fontSize: '16px', paddingBottom: '8px' }}>
            {t('최근 검색어')}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              overflowX: 'auto',
              marginTop: '8px',
            }}
          >
            {data?.map((res, idx) => (
              <RoundedButton
                key={`${res}-${idx}`}
                keyword={res}
                onClick={onClick}
                onDelete={onDelete}
              />
            ))}
          </div>
        </div>
      )}
      {resentViewData && (
        <div style={{ marginTop: '20px' }}>
          <div style={{ fontSize: '16px', paddingBottom: '8px' }}>
            {t('최근 본 사람')}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              overflowX: 'auto',
              marginTop: '8px',
            }}
          >
            {resentViewData?.map((res, idx) => (
              <SubProfile key={res.passportId} subPassport={res} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default RecentSearch;
