import { Dialog, Slide, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';

import { FormProvider, useForm } from 'react-hook-form';
import { useDebounce } from 'usehooks-ts';

import { useTranslation } from 'react-i18next';
import { Container } from '@/components/MobileOrganization/Search/styles';
import SVG from '@/components/SVG/SVG';
import CustomInput from '@/components/Organization/CustomInput';
import SearchResult from '@/components/MobileOrganization/Search/SearchResult';
import RecentSearch from '@/components/MobileOrganization/Search/RecentSearch';
import styled from '@emotion/styled';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Search = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const methods = useForm<{ keyword: string }>({
    defaultValues: { keyword: '' },
  });
  const searchString = methods.watch('keyword');
  const debouncedValue = useDebounce(searchString, 500);
  const { t } = useTranslation();
  return (
    <>
      <StyledSVGButton onClick={handleClickOpen}>
        <SVG
          name="search2"
          width={28}
          height={28}
          viewBox="0 0 28 28"
          className="svg-icon"
        />
      </StyledSVGButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <FormProvider {...methods}>
          <Container>
            <div
              style={{
                paddingTop: '18px',
                display: 'flex',
                gap: '20px',
                position: 'sticky',
                top: '0',
                backgroundColor: 'white',
              }}
            >
              <CustomInput
                placeHolder={t('이름, 소속, 번호 등을 검색해 보세요.')}
              />

              <button
                style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
                onClick={handleClose}
              >
                {t('취소')}
              </button>
            </div>
            {debouncedValue.length > 1 ? (
              <SearchResult keyword={debouncedValue} />
            ) : (
              <RecentSearch />
            )}
          </Container>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default Search;

const StyledSVGButton = styled.button`
  padding: 0;
  > svg {
    vertical-align: middle;
  }
`;
