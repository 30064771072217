import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <CircularProgress
      size={'30px'}
      style={{
        color: '#b7bbc2',
        alignSelf: 'center',
      }}
    />
  );
};
export default Loading;
