import { Container } from '@/components/Mobile/Header/styles';
import React, { CSSProperties, ReactNode } from 'react';
import Search from '@/components/MobileOrganization/Search';
import SvgImage from '@/components/Module/SvgImage';
import styled from '@emotion/styled';

type P = {
  title?: string;
  handleBack?(): void;
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  children?: ReactNode;
  titleStyle?: CSSProperties;
};

const Header = ({
  title,
  handleBack,
  leftButton,
  rightButton,
  children,
  titleStyle,
}: P) => {
  return (
    <Container>
      {children ? (
        children
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {handleBack && (
              <StyledSVGButton onClick={handleBack}>
                <SvgImage name={'back'} />
              </StyledSVGButton>
            )}
            <div style={titleStyle}>{title}</div>
            {leftButton}
          </div>
          {rightButton ? rightButton : <Search />}
        </>
      )}
    </Container>
  );
};
export default Header;

const StyledSVGButton = styled.button`
  padding: 0;
  > svg {
    vertical-align: middle;
  }
`;
