import { imgUrlChk } from '@/utils/function';
import NoProfileImg from '@/components/NoProfileImg';
import React, { useState } from 'react';
import { QuickSearch } from '@/types/api';
import { StyledImg, Layout } from '../styles';
import ProfileCardDialog from '@/components/MobileOrganization/Content/ProfileCardDialog';
import { VIP_PASSPORT_ID } from '@/utils/const';

type P = {
  subPassport: QuickSearch;
};

const Profile = ({ subPassport }: P) => {
  const [isImgError, setIsImgError] = useState<boolean>(false);
  const {
    profileImageUrl,
    name,
    positionName,
    divisionName,
    departmentName,
    passportId,
  } = subPassport;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Layout onClick={handleOpen}>
        {profileImageUrl ? (
          <StyledImg
            width={48}
            height={48}
            src={
              isImgError ? '/images/noProfile.svg' : imgUrlChk(profileImageUrl)
            }
            alt={`${name}-${passportId}-picture`}
            onError={() => setIsImgError(true)}
          />
        ) : (
          <NoProfileImg size={48} />
        )}
        <div style={{ textAlign: 'left' }}>
          <span style={{ fontSize: '16px', fontWeight: '700' }}>{name}</span>{' '}
          <span style={{ fontSize: '14px' }}>{positionName}</span>
          <div
            style={{
              fontSize: '12px',
              color: '#888D96',
              marginTop: '6px',
            }}
          >
            {passportId === VIP_PASSPORT_ID
              ? '대표이사'
              : `${divisionName} · ${departmentName}`}
          </div>
        </div>
      </Layout>
      <div
        style={{
          borderBottom: '1px solid #E7E9EC',
          width: '100%',
        }}
      />
      {open && (
        <ProfileCardDialog
          passportId={passportId}
          open={open}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default Profile;
