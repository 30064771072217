import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Empty = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        color: '#B7BBC2',
        textAlign: 'center',
      }}
    >
      <Image alt={'Empty'} src={'/images/empty.svg'} width={40} height={40} />
      <div>{t('일치하는 임직원 정보가 없어요.')}</div>
      <div>{t('검색어를 다시 한번 확인해 주세요.')}</div>
    </div>
  );
};

export default Empty;
