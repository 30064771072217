import { useEmpSearch } from '@/hooks/apiHooks';
import Loading from '@/components/MobileOrganization/Content/Loading';
import Profile from '@/components/MobileOrganization/Content/Profile';
import Empty from '@/components/MobileOrganization/Content/Empty';
import { CenterLayout } from '@/components/MobileOrganization/Search/SearchResult/styles';
import { useTranslation } from 'react-i18next';

type P = {
  keyword: string;
};
const SearchResult = ({ keyword }: P) => {
  const { data } = useEmpSearch(keyword);
  const { t } = useTranslation();

  return (
    <div>
      {data ? (
        <div>
          <div style={{ fontSize: '14px', padding: '20px 0' }}>
            {t('전체에서')}{' '}
            <span style={{ color: '#06C', fontWeight: '700' }}>
              {' '}
              {data.meta.totalCount}
            </span>
            {t('명을 찾았어요.')}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            {data.data.length > 0 ? (
              data.data.map((res) => (
                <Profile key={res.passportId} passport={res} />
              ))
            ) : (
              <CenterLayout>
                <Empty />
              </CenterLayout>
            )}
          </div>
        </div>
      ) : (
        <CenterLayout>
          <Loading />
        </CenterLayout>
      )}
    </div>
  );
};

export default SearchResult;
