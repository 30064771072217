import styled from '@emotion/styled';

const Container = styled.div`
  background-color: white;
  font-size: 16px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
  min-height: 56px;
`;

export { Container };
